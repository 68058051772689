<template>
  <div style="width: 100%; height: 100%">
    <div class="introduction-wrapper">
      <div class="text-wrapper">
        <p>飞象是一家专业从事让数控加工机床自主思考的高新技术企业</p>
        <p>我们专注于数控加工过程中的数据集成、刀具保护、加工提效、寿命监控</p>
        <p>我们的使命是用科技为制造创造更多价值，助力企业实现制造转型</p>
      </div>
      <div class="module-wrapper">
        <div class="modules">
          <div class="module">
            <a href="about.html?p=j"">
              <div class="backimg1"></div>
              <p>公司简介</p>
            </a>
          </div>
          <div class="module">
            <a href="about.html?p=w"">
              <div class="backimg2"></div>
              <p>企业文化</p></a
            >
          </div>
          <div class="module">
            <a href="about.html?p=d">
              <div class="backimg3"></div>
              <p>办公地点</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  props: [],
  components: {},
  methods: {},
  mounted() {}
};
</script>



<style lang="scss" scoped >
.introduction-wrapper {
  .text-wrapper {
    padding: 9vh 0 2vh 0;
    p {
      margin: 0;
      padding: 0;
      height: 5vh;
      line-height: 5vh;
      text-align: center;
      font-size: 24px;
      color: $fx-font-color;
      letter-spacing: 1px;
      font-family: "FXText";
    }
  }
  .module-wrapper {
    margin-top: 3vh;
    position: relative;
    width: 100%;
    height: 300px;
    box-sizing: border-box;

    .modules {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .module {
        position: relative;
        width: 400px;
        height: 250px;
        overflow: hidden;
        a {
          text-decoration: none;
          .backimg1,
          .backimg2,
          .backimg3 {
            width: 100%;
            height: 100%;
            background: url("../../../../assets/gongsi.jpg");
            background-size: cover;
            position: relative;
            transition: all 0.3s ease-out;
            background-repeat: no-repeat;
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.3);
              transition: all 0.3s ease-out;
            }
          }
          .backimg2 {
            background: url("../../../../assets/wenhua.png");
            background-size: cover;
          }
          .backimg3 {
            background: url("../../../../assets/bangong.png");
            background-size: cover;
          }
          p {
            font-family: "FXTitle";
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            padding: 0;
            margin: 0;
            &::after {
              content: "";
              display: block;
              width: 0%;
              height: 3px;
              background: #fff;
              // transform: scale(0.001, 1);
              transition: 0.3s ease-out;
            }
          }
        }

        &:nth-of-type(2) {
          margin-left: 20px;
        }
        &:nth-of-type(3) {
          margin-left: 20px;
        }
        &:hover {
          a {
            .backimg1,
            .backimg2,
            .backimg3 {
              transform: scale(1.05, 1.05);
              &::after {
                background: rgba(0, 82, 217, 0.7);
              }
            }
            p {
              &::after {
                // transform: scale(1, 1);
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>