<template>
  <div style="width: 100%; height: 100%">
    <div class="footer-wrapper" id="fxfooter">
      <div class="myfooter">
        <div class="title">联系我们</div>
        <div class="icons">
          <!-- <i class="iconfont icon-aui-icon-weichat"></i> -->
          <i class="iconfont icon-phone"></i>
          15763363531
        </div>
        <div class="fxlogo-wrapper">
          <div class="fxlogo">
            <img src="../../assets/logo.png" alt="logo" />
            <p>飞象科技</p>
          </div>
        </div>
      </div>
      <div class="beian">
        <a href="https://beian.miit.gov.cn/" target="_blank">
          备案号：鲁ICP备2021017415号-1
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: [],
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.footer-wrapper {
  margin-top: 10vh;
  width: 100%;
  height: 110px;
  background: #1b1f25;
  position: relative;
  .myfooter {
    width: 1200px;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: 100px 1fr 1fr;
    color: #fff;

    .title {
      line-height: 100px;
      font-size: 18px;
    }
    .icons {
      height: 100px;
      display: flex;
      align-items: center;
      font-size: 18px;
      i {
        &:nth-of-type(1) {
          font-size: 40px;
          // color: #22d86f;
          margin-right: 40px;
        }
        &:nth-of-type(1) {
          font-size: 28px;
          margin-right: 4px;
        }
      }
    }
    .fxlogo-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      padding-right: 200px;
      box-sizing: border-box;
      .fxlogo {
        display: grid;
        grid-template-columns: 80px 1fr;
        align-items: center;
        img {
          width: 80px;
        }
        p {
          font-family: "FXTitle";
          font-size: 30px;
          margin-left: -5px;
          color: #fff;
          display: block;
          margin: 0px;
          margin-left: -5px;
        }
      }
    }
  }
  .beian {
    text-align: center;
    position: absolute;
    bottom: 40px;
    width: 100%;
    a {
      font-size: 14px;
      text-decoration: none;
      color: rgb(194, 194, 194);
    }
  }
}
</style>
