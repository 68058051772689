<template>
  <div>
    <fxheader :fixBackColor="false"></fxheader>
    <banner></banner>
    <intro></intro>
    <linkarea></linkarea>
    <kehu></kehu>
    <yuanjing></yuanjing>
    <fxfooter></fxfooter>
  </div>
</template>


<script>
import fxheader from "@/components/header";
import banner from "./components/banner";
import intro from "./components/introduction";
import linkarea from "./components/linkarea";
import yuanjing from "./components/yuanjing";
import kehu from "./components/kehu";
import fxfooter from "../../components/footer";
export default {
  data() {
    return {};
  },
  props: [],
  components: {
    fxheader,
    banner,
    intro,
    linkarea,
    kehu,
    yuanjing,
    fxfooter
  },
  methods: {},
  mounted() {}
};
</script>



<style lang="scss" scoped >
</style>