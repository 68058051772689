<template>
  <div style="width: 100%; height: 100%">
    <div class="kehu-wrapper">
      <div class="kehu">
        <div class="img-wrapper">
          <div class="italic">
            <div class="backimg"></div>
          </div>
        </div>
        <div class="content-wrapper">
          <div class="content">
            <a href="javascript:" @click="yuanjing()">
              <p>
                让客户更省心
                <i class="iconfont icon-arrow-right"></i>
              </p>
              <p>
                飞象一直坚持客户第一、客户价值为先的原则
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  props: [],
  components: {},
  methods: {
    yuanjing() {
      window.open("about.html?p=y", "_self");
    }
  },
  mounted() {}
};
</script>



<style lang="scss" scoped >
.kehu-wrapper {
  width: 100%;
  height: 500px;
  margin-top: 10vh;
  position: relative;
  .kehu {
    width: 1200px;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    .img-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .italic {
        width: 100%;
        height: 100%;
        background: #888;
        transform: rotate(5deg) scale(1.12) translate(-10%, 0);
        overflow: hidden;
        .backimg {
          width: 100%;
          height: 100%;
          background-image: url("../../../../assets/kehu.jpg");
          background-size: cover;
          transform: scale(0.9) translate(10%, 0) rotate(-5deg);
        }
      }
    }
    .content-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      .content {
        position: absolute;
        top: 50%;
        transform: translate(20%, -50%);
        a {
          text-decoration: none;
          p {
            font-family: "FXText";
            font-size: 30px;
            color: $fx-font-color;
            margin: 0;
            line-height: 50px;
            i {
              font-size: 24px;
              color: $fx-font-color;
            }
            &:nth-of-type(2) {
              font-family: "FXTextXi";
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
</style>