import Vue from 'vue'
import App from './index.vue'

import '@/fonts/font.css'
import '@/icons/iconfont.css'
import { addclass } from '../../utils/directives'

Vue.directive('addclass', addclass)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
