<template>
  <div style="width: 100%; height: 100%">
    <div class="linkarea-wrapper">
      <div class="linkarea">
        <div class="links-wrapper">
          <div class="link-wrapper">
            <div
              :class="['link', { active: i == active }]"
              v-for="(item, i) in linklist"
              :key="i"
              @mouseenter="changeLink(i)"
            >
              <a href="product.html?p=p">
                <p>
                  {{ item.title }}
                  <i class="iconfont icon-arrow-right"></i>
                  <span>{{ item.content }}</span>
                </p>
              </a>
            </div>
          </div>
        </div>

        <div class="backimg-wrapper">
          <div
            :class="['backimg', { active: i - 1 == active }]"
            v-for="i in 4"
            :key="i"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      active: 0,
      linklist: [
        {
          title: "让加工更高效",
          content: "提高加工效率 > 15%"
        },
        {
          title: "让成本更节省",
          content: "节省制造费用 > 10%"
        },
        {
          title: "让数据更直观",
          content: "加工数据图表随时掌握"
        },
        {
          title: "让机床更智能",
          content: "机床自动识别加工异常"
        }
      ]
    };
  },
  props: [],
  components: {},
  methods: {
    changeLink(i) {
      this.active = i;
    }
  },
  mounted() {}
};
</script>



<style lang="scss" scoped >
.linkarea-wrapper {
  width: 100%;
  height: 600px;
  background: #1b1f25;
  margin-top: 8vh;
  position: relative;
  .linkarea {
    width: 1200px;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: 400px 1fr;
    .links-wrapper {
      position: relative;
      .link-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        .link {
          color: #fff;
          font-family: "FXText";
          font-size: 30px;
          position: relative;
          a {
            text-decoration: none;
            p {
              transition: all 0.3s ease-out;
              margin: 30px 0;
              line-height: 80px;
              padding-left: 40px;
              i {
                font-size: 24px;
                padding-left: 10px;
                transition: all 0.3s ease-out;
                opacity: 0;
              }
              span {
                display: block;
                line-height: 20px;
                font-size: 18px;
                font-family: "FXTextXi";
              }
              transform: translate(-20px, 0);
              color: rgba(255, 255, 255, 0.4);
            }
          }

          &::before {
            content: "";
            display: block;
            width: 6px;
            height: 80px;
            background: #1e52d8;
            position: absolute;
            top: 24px;
            left: 0;
            transition: all 0.3s ease-out;
            transform: translate(-40px, 0);
            opacity: 0;
          }
        }
        .active {
          &::before {
            transform: translate(0, 0);
            opacity: 1;
          }
          a {
            p {
              color: #fff;
              transform: translate(0, 0);
              i {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .backimg-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      .backimg {
        width: 100%;
        height: 80%;
        background-size: cover;
        position: absolute;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-out;
        opacity: 0;
        &:nth-of-type(1) {
          background-image: url("../../../../assets/zhikong.png");
        }
        &:nth-of-type(2) {
          background-image: url("../../../../assets/chengben.png");
        }
        &:nth-of-type(3) {
          background-image: url("../../../../assets/data.png");
        }
        &:nth-of-type(4) {
          background-image: url("../../../../assets/jichuang.png");
        }
      }
      .active {
        opacity: 1;
      }
    }
  }
}
</style>