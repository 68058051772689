<template>
  <div style="width: 100%; height: 100%">
    <!-- 如果fixBackColor为true则永远白色background -->
    <div
      :class="[
        'header-wrapper',
        { active: active },
        { active_: active_ || fixBackColor }
      ]"
      @mouseenter="addClass()"
      @mouseleave="removeClass()"
    >
      <div class="title-wrapper">
        <!-- 初，将这个换成白的 -->
        <img
          v-show="!img_show && !fixBackColor"
          src="../../assets/logo.png"
          alt="logo"
        />
        <img
          v-show="img_show || fixBackColor"
          src="../../assets/_logo.png"
          alt="logo"
        />
        <div class="content">飞象科技</div>
      </div>
      <div class="menu-wrapper">
        <div><a href="index.html">公司首页</a></div>
        <div><a href="product.html">产品概况</a></div>
        <div><a href="#">客户案例</a></div>
        <div><a href="about.html">关于飞象</a></div>
      </div>
    </div>
  </div>
</template>


<script>
// 引入节流函数
import { throttle } from "../../utils/fun";
export default {
  data() {
    return {
      // 保证解除的函数与绑定的函数是同一个
      fn: throttle(this.changeClass, 40),
      // background #fff 的使用变量
      active: false,
      active_: false
    };
  },
  computed: {
    img_show() {
      return this.active || this.active_;
    }
  },
  props: ["fixBackColor"],
  components: {},
  methods: {
    // 初始化变量值
    activeValue() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.active = scrollTop < 80 ? false : true;
    },
    // 节流回调函数,滚动事件
    changeClass() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.active = scrollTop < 80 ? false : true;
    },
    // scroll监控
    init() {
      if (!this.fixBackColor) {
        window.addEventListener("scroll", this.fn);
      }
    },
    // 解除scroll监控
    uninit() {
      if (!this.fixBackColor) {
        window.removeEventListener("scroll", this.fn);
      }
    },
    // 鼠标放置上去显示
    addClass() {
      this.active_ = true;
    },
    // 鼠标离开
    removeClass() {
      this.active_ = false;
    }
  },
  mounted() {
    // 初始化变量值
    this.activeValue();
    // 初始化监控
    this.init();
  },
  destroyed() {
    // 销毁时解除
    this.uninit();
  }
};
</script>



<style lang="scss" scoped >
.header-wrapper {
  z-index: 10000;
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 72px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 500px 1fr;
  .title-wrapper {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 80px 1fr;
    line-height: 72px;
    position: relative;
    padding-left: 100px;
    img {
      width: 80px;
      margin-top: 12px;
    }
    .content {
      font-family: "FXTitle";
      font-size: 30px;
      margin-left: -5px;
      color: #fff;
    }
  }
  .menu-wrapper {
    display: flex;
    & > div {
      margin-left: 58px;
      line-height: 72px;
      a {
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        font-family: "PingFangSC-Regular";
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 3px;
          background: #114785;
          position: relative;
          margin-top: -22px;
          transform: scale(0.001, 1);
          opacity: 0;
          transition: all 0.2s ease-out;
        }
        &:hover {
          &::after {
            opacity: 1;
            transform: scale(1, 1);
          }
        }
      }
    }
  }
}
.header-wrapper.active {
  background: #fff;
  .title-wrapper {
    .content {
      color: #114775;
    }
  }
  .menu-wrapper {
    & > div {
      a {
        color: #303133;
      }
    }
  }
}
.header-wrapper.active_ {
  background: #fff;
  .title-wrapper {
    .content {
      color: #114775;
    }
  }
  .menu-wrapper {
    & > div {
      a {
        color: #303133;
      }
    }
  }
}
</style>