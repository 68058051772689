export function throttle(fn, delay) {
  let flag = true
  return function() {
    if (flag) {
      flag = false
      fn()
      setTimeout(() => {
        flag = true
      }, delay)
    }
  }
}
