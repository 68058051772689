<template>
  <div style="width: 100%; height: 100%">
    <div class="yuanjing-wrapper">
      <div class="backimg"></div>
      <div class="content">
        <p v-addclass="'fadeIn'">
          飞象科技
        </p>
        <p v-addclass="'fadeIn'">用科技赋能</p>
        <p v-addclass="'fadeIn'">智慧加工产品和服务的领先玩家</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  props: [],
  components: {},
  methods: {},
  mounted() {}
};
</script>



<style lang="scss" scoped >
.yuanjing-wrapper {
  width: 100%;
  height: 50vh;
  margin-top: 10vh;
  position: relative;
  .backimg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url("../../../../assets/yuanjing.jpg");
  }
  .content {
    position: absolute;
    top: 0;
    font-family: "FXTitle";
    font-size: 40px;
    color: $fx-font-color;
    left: 10%;
    top: 50%;
    transform: translate(0, -50%);
    p {
      margin: 0;
      line-height: 60px;
      padding-left: 46px;
      transform: translate(0, 100px);
      opacity: 0;
      transition: all 1s;
      &:nth-of-type(1) {
        font-size: 50px;
        padding-left: unset;
      }
    }
    .fadeIn {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
}
</style>