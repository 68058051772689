<template>
  <div style="width: 100%; height: 100%;">
    <div class="banner-wrapper">
      <div class="backimg-wrapper">
        <div class="backimg"></div>
      </div>
      <div class="foreground"></div>
      <div class="content" ref="content">
        <p>用科技为制造创造更多价值</p>
        <p>助力企业实现智造转型</p>
      </div>
      <div class="slider-wrapper">
        <div class="slider">
          <div class="slider-node"></div>
        </div>
        <div class="text">滑动</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: [],
  components: {},
  methods: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
.banner-wrapper {
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: auto;
  .backimg-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .backimg {
      background: url('../../../../assets/test.png');
      background-attachment: fixed;
      background-size: cover;
      width: 100%;
      height: 100%;
      animation: backimg 1s linear forwards;
    }
  }
  .foreground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  .content {
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    box-sizing: border-box;
    & > p {
      font-weight: lighter;
      color: #fff;
      font-family: 'FXTitle';
      font-size: 50px;
      letter-spacing: 4px;
      &:first-child {
        font-size: 70px;
        text-align: center;
        animation: content_p1 1s forwards;
      }
      &:last-child {
        text-align: center;
        margin-top: -60px;
        animation: content_p2 1s forwards;
      }
    }
  }

  .slider-wrapper {
    position: absolute;
    bottom: 40px;
    color: #fff;
    left: 50%;
    transform: translate(-50%, 0);
    animation: sliderwrapper 1s forwards;
    .slider {
      height: 30px;
      margin-bottom: 6px;
      position: relative;
      overflow: hidden;
      .slider-node {
        width: 2px;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        animation: slider 3.2s infinite linear;
      }
    }
  }
}

// 动画定义
@keyframes backimg {
  0% {
    transform: scale(1.4, 1.4) rotate(-5deg);
    filter: blur(3px);
  }
  100% {
    transform: scale(1.1, 1.1) rotate(0deg);
    filter: blur(1px);
  }
}
@keyframes content_p1 {
  0% {
    opacity: 0;
    transform: translate(-300px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes content_p2 {
  0% {
    opacity: 0;
    transform: translate(300px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes slider {
  0% {
    transform: translate(-50%, -100%);
  }
  25% {
    transform: translate(-50%, 0);
  }
  75% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, 100%);
  }
}
@keyframes sliderwrapper {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
